import React from 'react';
import {
  PortableText as PortableTextComponent,
  PortableTextReactComponents,
} from '@portabletext/react';
import { Block } from 'lib/cms/types';
import { Link } from 'components/base';
import Language from 'constants/Language';
import { PortableTextColors } from 'constants/Colors';
import {
  Video,
  ParagraphWithHeadingAndButton,
  FullWidthImageCaption,
  HalfWidthImageCaption,
  LineBreak,
  QuoteModule,
} from 'constants/PortableTextSerializer';

type Props = {
  blocks: Block[];
};

const colorComponents = Object.entries(PortableTextColors).reduce(
  (colorComponents: { [key: string]: React.FC }, [color, hex]) => {
    colorComponents[color] = (props: any) => {
      return <span style={{ color: hex as string }}>{props.children}</span>;
    };
    return colorComponents;
  },
  {}
);

const serializers: Partial<PortableTextReactComponents> = {
  marks: {
    ...colorComponents,
    link: (props) => {
      return (
        <Link
          to={props.value.href}
          openInNewTab={props.value.blank}
          ariaLabel={Language.t('Global.portableTextLink.ariaLabel', {
            label: props.children,
          })}
        >
          {props.children}
        </Link>
      );
    },
  },
  types: {
    video: Video,
    paragraphWithHeadingAndButton: (props) => {
      return <ParagraphWithHeadingAndButton props={props.value} />;
    },
    fullWidthImageCaption: FullWidthImageCaption,
    halfWidthImageCaption: HalfWidthImageCaption,
    lineBreak: LineBreak,
    quoteModule: QuoteModule,
  },
};

const PortableText: React.FC<Props> = ({ blocks }) => {
  return <PortableTextComponent value={blocks} components={serializers} />;
};

export default PortableText;
